<template>
  <v-dialog v-model="deleteNoteOfInterest.dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline">Archive Note Of Interest</v-card-title>
      <v-card-text
        >Are you sure you wish to archive
        {{ deleteNoteOfInterest.notes_of_interest.id }}?</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
        <v-btn
          color="success"
          depressed
          :loading="deleteNoteOfInterest.loading"
          @click="saveDelete"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      deleteNoteOfInterest: {
        dialog: false,
        notes_of_interest: {},
        loading: false,
      },
    };
  },

  methods: {
    openDelete(notes_of_interest) {
      this.deleteNoteOfInterest.notes_of_interest = notes_of_interest;
      this.deleteNoteOfInterest.dialog = true;
    },

    resetDelete() {
      this.deleteNoteOfInterest.dialog = false;
      this.deleteNoteOfInterest.notes_of_interest = {};
      this.deleteNoteOfInterest.loading = false;
    },

    saveDelete() {
      this.deleteNoteOfInterest.loading = true;

      this.$store
        .dispatch("melrose/propertiesStore/deleteNoteOfInterest", {
          appId: this.$route.params.id,
          noteOfInterestId: this.deleteNoteOfInterest.notes_of_interest.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteNoteOfInterest.loading = false;
        });
    },
  },
};
</script>
